import { fetcherNew1819 } from "@utils/fetcherNew1819";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import useUser from "./use_user";
import ability from "@utils/ability";

export const usePostalCodes = () => {
    return useSWR("mitt/postal-codes", fetcherNew1819);
};

export const useSmsUsers = () => {
    return useSWR("mitt/sms/users", fetcherNew1819);
};

export const useAnsweringCompanies = () => {
    return useSWR("mitt/answering/companies", fetcherNew1819);
};

export const useAnsweringCompanyUsers = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/answering/company/${id}/users`, fetcherNew1819);
};

export const useArchivedAnsweringCompanies = () => {
    return useSWR("mitt/answering/companies/archived", fetcherNew1819);
};

export const useEmployees = () => {
    return useSWR("mitt/employees", fetcherNew1819);
};

export const useSvarBilling = (month) => {
    if (!month) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/billing/svar/?month=${month}`, fetcherNew1819);
};

export const useRenamePeople = () => {
    return useSWR("mitt/project/rename-people", fetcherNew1819);
};

export const useBillingAnswerCompanies = (startDay, endDay, startTime, endTime) => {
    return useSWR(
        `mitt/billing/answer-companies?start-day=${startDay}&end-day=${endDay}&start-time=${startTime}&end-time=${endTime}`,
        fetcherNew1819
    );
};

export const useBillingChat = (companyID, startDay, endDay, startTime, endTime) => {
    return useSWR(
        `mitt/billing/chat/${companyID}?start-day=${startDay}&end-day=${endDay}&start-time=${startTime}&end-time=${endTime}`,
        fetcherNew1819
    );
};

export const useBillingChatCompanies = () => {
    return useSWR("mitt/billing/chat", fetcherNew1819);
};

export const useBillingThjonustuver = (startDay, endDay) => {
    return useSWR(
        `mitt/billing/thjonustuver?start-day=${startDay}&end-day=${endDay}`,
        fetcherNew1819
    );
};

export const useAnsweringCompany = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/answering/company?id=${id}`, fetcherNew1819);
};

export const useBillingSms = (startDay, endDay) => {
    return useSWR(`mitt/billing/sms?start-day=${startDay}&end-day=${endDay}`, fetcherNew1819);
};
export const useBillingSmsNew = (startDay, endDay) => {
    return useSWR(`mitt/billing/sms/new?start-day=${startDay}&end-day=${endDay}`, fetcherNew1819);
};

export const useBillingApi = (startDay, endDay) => {
    return useSWR(`mitt/billing/api?start-day=${startDay}&end-day=${endDay}`, fetcherNew1819);
};
export const useRegistrationLog = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/registration/${id}/log`, fetcherNew1819);
};

export const useSearchWords = (id, startDay, endDay) => {
    if (!id) return useSWRImmutable(null, fetcherNew1819);
    return useSWRImmutable(
        `mitt/registration/${id}/search-words?start-day=${startDay}&end-day=${endDay}`,
        fetcherNew1819
    );
};

export const useMySmsAccounts = () => {
    return useSWR("mitt/sms/user", fetcherNew1819);
};

export const useMittStatistics = () => {
    return useSWR("mitt/statistics", fetcherNew1819);
};

export const useThjonustuverStatistics = (startDay, endDay, user_extension) => {
    if (user_extension === undefined) user_extension = "";
    return useSWR(
        `mitt/statistics/thjonustuver?start-day=${startDay}&end-day=${endDay}&user-extension=${user_extension}`,
        fetcherNew1819
    );
};

export const useQueueStatistics = (startDay, endDay, companyID, interval) => {
    if (companyID === undefined) companyID = "";
    if (interval === undefined) interval = "day";
    return useSWRImmutable(
        `mitt/answering/company/${companyID}/statistics?start-day=${startDay}&end-day=${endDay}&interval=${interval}`,
        fetcherNew1819
    );
};

export const useQueueCalls = (startDay, endDay, companyID) => {
    if (!companyID) return useSWRImmutable(null, fetcherNew1819);
    return useSWRImmutable(
        `mitt/answering/company/${companyID}/statistics/calls?start-day=${startDay}&end-day=${endDay}`,
        fetcherNew1819
    );
};

export const useAgentExtensions = (startDay, endDay) => {
    return useSWR(
        `mitt/statistics/thjonustuver/agent-extensions?start-day=${startDay}&end-day=${endDay}`,
        fetcherNew1819
    );
};

export const useRoles = () => {
    return useSWR("mitt/roles", fetcherNew1819);
};

export const useApiClients = () => {
    return useSWR("mitt/api-clients", fetcherNew1819);
};

export const useCities = () => {
    return useSWR("mitt/postal-code/cities", fetcherNew1819);
};
export const useAllowedCities = () => {
    return useSWR("mitt/sms/user/cities", fetcherNew1819);
};

export const useCityPostalCodes = (city) => {
    return useSWRImmutable(`mitt/postal-code/city-postal-codes?city=${city}`, fetcherNew1819);
};
export const useStreets = (city, postalCode) => {
    let url = `mitt/postal-code/streets?city=${city}`;
    if (postalCode !== undefined && postalCode !== null && postalCode !== "all")
        url += `&postal-code=${postalCode}`;
    if (city === undefined) url = null;
    if (city === null) url = null;
    if (city === "") url = null;
    return useSWR(url, fetcherNew1819);
};

export const useSmsPhoneLists = () => {
    return useSWR("mitt/sms/user/phone-lists", fetcherNew1819);
};

export const useSmsPhoneList = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/sms/user/phone-lists/${id}`, fetcherNew1819);
};

export const useSentMessages = () => {
    return useSWR("mitt/sms/user/messages", fetcherNew1819);
};

export const useSentMessageDetails = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/sms/user/messages/${id}`, fetcherNew1819);
};

export const usePhoneCompanyUpdates = () => {
    return useSWR("mitt/phone-company-updates", fetcherNew1819);
};

export const useSearchPhoneCompanyUpdates = (phone) => {
    if (!phone) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/phone-company-updates/${phone}`, fetcherNew1819);
};

export const useNewCompanies = () => {
    return useSWR("mitt/new-companies", fetcherNew1819);
};

export const useAdvertisementByKennitala = (kennitala, startDay, endDay) => {
    return useSWR(
        `mitt/advertisements/kennitala/${kennitala}?start-day=${startDay}&end-day=${endDay}`,
        fetcherNew1819
    );
};

export const useHubspotInboxes = () => {
    return useSWR("mitt/answering/inboxes", fetcherNew1819);
};

export const usePackages = () => {
    const user = useUser();
    if (ability(user).cannot("read", "subscriptions")) return useSWR(null, fetcherNew1819);
    return useSWR("mitt/subscriptions", fetcherNew1819);
};

export const usePackage = (id) => {
    if (id === null || id === undefined) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/subscriptions/${id}`, fetcherNew1819);
};

export const useAddressSuggestions = (query) => {
    if (query === null || query === undefined || query === "") return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/stadfangaskra?q=${query}`, fetcherNew1819);
};

export const useCompanySuggestions = (query, includeUnregistered) => {
    if (query === null || query === undefined) return useSWR(null, fetcherNew1819);
    if (includeUnregistered) {
        return useSWR(`mitt/companies/search?q=${query}&include-unregistered=true`, fetcherNew1819);
    }
    return useSWR(`mitt/companies/search?q=${query}`, fetcherNew1819);
};

export const useAddressRequests = () => {
    return useSWR("mitt/address-request", fetcherNew1819);
};

export const useCompanyRequests = () => {
    return useSWR("mitt/company-request", fetcherNew1819);
};

export const useCallCenterComments = () => {
    return useSWR("mitt/thjonustuver/comments", fetcherNew1819);
};

export const useCallCenterComment = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/thjonustuver/comments/${id}`, fetcherNew1819);
};

export const useCompanyAccess = () => {
    return useSWR("mitt/company-access", fetcherNew1819);
};

export const useCompanyUsers = (kennitala) => {
    return useSWR(`mitt/company-access/${kennitala}`, fetcherNew1819);
};

export const useListavinnsla = () => {
    return useSWR("mitt/listavinnsla", fetcherNew1819);
};

export const useListavinnslaList = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/listavinnsla/${id}`, fetcherNew1819);
};

export const usePhoneCallsTodayForNumber = (number) => {
    return useSWR(`mitt/statistics/thjonustuver/phone-today/${number}`, fetcherNew1819);
};

export const usePhoneCalls3MonthsForNumber = (number) => {
    return useSWR(`mitt/statistics/thjonustuver/phone-3months/${number}`, fetcherNew1819);
};

export const useStatus = () => {
    // Only fetch if user is logged in
    const user = useUser();

    if (!user) {
        return useSWR(null, fetcherNew1819);
    }

    return useSWR("mitt/status", fetcherNew1819);
};

export const useListavinnslaCategories = () => {
    return useSWR("mitt/listavinnsla/categories", fetcherNew1819);
};

export const useAdvertisements = () => {
    return useSWR("mitt/advertisements", fetcherNew1819);
};

export const useAdvertisementStatistics = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/advertisements/${id}/statistics`, fetcherNew1819);
};

export const useCallCampaigns = () => {
    return useSWR("mitt/call-campaigns", fetcherNew1819);
};

export const useMyCallCampaigns = () => {
    return useSWR("mitt/call-campaigns/my", fetcherNew1819);
};

export const useCallCampaign = (id) => {
    return useSWR(`mitt/call-campaigns/${id}`, fetcherNew1819);
};

export const useCallCampaignLists = (id) => {
    if (!id) return useSWR(null, fetcherNew1819);
    return useSWR(`mitt/call-campaigns/${id}/lists`, fetcherNew1819);
};
